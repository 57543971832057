@use "sass:math";
main.route-plone {
  .plone-listing {
  }

  .plone-details-view {
    .main-details {
      .card-header {
        display: flex;

        .card-img {
          height: 3rem;
          width: 3rem;
          align-self: center;
          margin-right: math.div($grid-gutter-width, 3);
        }

        [role='edit'] {
          align-self: center;
        }
      }
    }

    .grid {
      display: grid;
      grid-template-columns: 1fr;

      > div {
        position: relative;
        padding: math.div($grid-gutter-width, 2);
      }

      @media(min-width: map-get($grid-breakpoints, "lg")) {
        grid-template-columns: 1fr 1fr;
        > div {

          &:after {
            content: "";
            width: 1px;
            position: absolute;
            right: -0.5px;
            top: 10%;
            bottom: 10%;
            background-color: $gray-200;
          }

          &:last-child:after {
            display: none;
          }
        }
      }

    }

    .packages {
      .card-body {
        padding: 0;
      }

      .filter {
        margin: 5px;
        display: flex;
        align-items: flex-end;
        flex-direction: column;
      }
    }
  }

  .plone-modal {
    label {
      font-weight: bold;
    }

    .tab-pane {
      padding-top: math.div($grid-gutter-width, 2);
    }

    input[type="checkbox"] {
      margin-left: 20px;
    }
  }
}
