@use "sass:math";

main.route-server {
  .listing-view {
    header {
      margin-top: math.div($grid-gutter-width, 2);
      display: flex;

      h1 {
        flex-grow: 1;
      }

      [role] {
        align-self: center;
      }
    }
    .server-list {
      margin-top: math.div($grid-gutter-width, 2);
    }
    .last-update {
      margin-right: math.div($grid-gutter-width, 2);
    }
  }

  .server-details-view {
    .main-details > .card-header {
      display: flex;
    }
    .grid {
      display: grid;
      flex-direction: row;
      grid-template-columns: 1fr;

      > div {
        position: relative;
        padding: math.div($grid-gutter-width, 2);
      }

      @media(min-width: map-get($grid-breakpoints, "lg")) {
        grid-template-columns: 1fr 1fr;
        > div {
          position: relative;
          &:after {
            content: "";
            width: 1px;
            position: absolute;
            right: -0.5px;
            top: 10%;
            bottom: 10%;
            background-color: $gray-200;
          }

          &:last-child:after {
            display: none;
          }
        }
      }
    }

    .server-properties {

    }

    .plones {
      ul {
        list-style: none;
        display: flex;
        flex-wrap: wrap;

        li {
          display: block;

          a {
            display: inline-block;
          }
        }
      }
      .plone {
        > a {
          display: flex;
          flex-direction: row;
        }
        .title {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          align-self: center;
          margin-left: math.div($grid-gutter-width, 3);
          .domain {
            font-size: 0.8em;
          }
        }
      }
    }
  }
}