// tooltips
@use "sass:math";
$tooltip-bg: $card-bg;
$tooltip-color: $body-color;
$tooltip-border: 0.05rem solid $card-border-color;
$tooltip-arrow-border-color: $card-border-color;
$tooltip-arrow-border-width: 0.05rem;

.popover {

  .popover-inner {
    border: $tooltip-border;
    background-color: $tooltip-bg;
    color: $tooltip-color;
    max-width: none;
    border-radius: 3px;
  }

  .arrow {
    &:before {
      z-index: 2;
    }

    &:after {
      z-index: 1;
      position: absolute;
      content: "";
      border-color: transparent;
      border-style: solid;
    }
  }

  &.bs-popover-auto[x-placement^=top] .arrow {
    &:before {
      top: -$tooltip-arrow-border-width;
      border-top-color: $card-bg;
    }

    &:after {
      top: 0;
      border-width: $tooltip-arrow-height+$tooltip-arrow-border-width (math.div($tooltip-arrow-width, 2) + $tooltip-arrow-border-width) 0;
      border-top-color: $tooltip-arrow-border-color;
    }
  }

  &.bs-popover-auto[x-placement^=bottom] .arrow {
    &:before {
      bottom: -$tooltip-arrow-border-width;
      border-bottom-color: $card-bg;
    }

    &:after {
      bottom: 0;
      border-width: 0 (math.div($tooltip-arrow-width, 2) + $tooltip-arrow-border-width) $tooltip-arrow-height+$tooltip-arrow-border-width;
      border-bottom-color: $tooltip-arrow-border-color;
    }
  }

  &.bs-popover-auto[x-placement^=left] .arrow {
    &:before {
      left: -$tooltip-arrow-border-width;
      border-left-color: $card-bg;
    }

    &:after {
      left: 0;
      border-width: (math.div($tooltip-arrow-width, 2) + $tooltip-arrow-border-width) 0 (math.div($tooltip-arrow-width, 2) + $tooltip-arrow-border-width) $tooltip-arrow-height+$tooltip-arrow-border-width;
      border-left-color: $tooltip-arrow-border-color;
    }

  }

  &.bs-popover-auto[x-placement^=right] .arrow {
    &:before {
      right: -$tooltip-arrow-border-width*2;
      border-right-color: $card-bg;
      left: auto;
    }

    &:after {
      right: 0;
      border-width: (math.div($tooltip-arrow-width, 2) + $tooltip-arrow-border-width) $tooltip-arrow-height+$tooltip-arrow-border-width (math.div($tooltip-arrow-width, 2) + $tooltip-arrow-border-width) 0;
      border-right-color: $tooltip-arrow-border-color;
    }
  }

}

.nav-link {
  cursor: pointer;
}