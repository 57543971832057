#sidenav-main {
  .navbar-nav .icon {
    margin-right: 12px;
    max-height: 1.5rem;
    max-width: 1.5rem;
  }

  @media(max-width: map-get($grid-breakpoints, "md") - 1) {
    > .container-fluid {
      display: grid;
      grid-template-columns: min-content auto min-content;
    }
  }

  .navbar-brand {
    text-align: center;
  }

  .title {
  }

  .navbar-nav {
    .navbar-nav {
      margin: 0;

      .nav-item {
        padding-left: 1.5rem;
      }
    }
  }

  #profile-overview {
    background-color: rgba(0, 0, 0, 0.05);
    border-top: 1px solid $primary;
    padding: $navbar-padding-y $navbar-padding-x;

    .dropdown > .nav-link {
      display: flex;
      align-items: center;
      padding: 0;

      .avatar {
        margin-right: 5px;
        cursor: pointer;
      }

      .name {
        cursor: pointer;
        flex-grow: 1;
        text-align: left;
        padding: 5px;
      }
    }

    @media(max-width: map-get($grid-breakpoints, "md") - 1) {
      background-color: transparent;
      padding: 0;
      border-top: none;
      .dropdown > .nav-link {
        .avatar {
          order: 0;
        }

        .dropdown-icon {
          order: 1;
          transform: rotate(180deg);
        }
      }
      .name {
        color: $dropdown-header-color;
      }
    }
    @media(min-width: map-get($grid-breakpoints, "md")) {
      margin: (-$navbar-padding-y) (-$navbar-vertical-padding-x);
      .dropdown {
        display: block;
      }
    }
  }
}

#toast-notifications {
  position: fixed;
  right: 1rem;
  bottom: 1rem;
  z-index: 5;

  .toast {
    cursor: pointer;
  }
}