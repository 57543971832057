@use "sass:math";
main.route-gitlab {
  .project-listing {
    .list-actions {
      margin-bottom: math.div($grid-gutter-width, 2);

      .state-overview {
        padding: math.div($grid-gutter-width, 3) math.div($grid-gutter-width, 2);
        .progress {
          height: 0.8rem;
          margin-bottom: 0;
        }
      }

      .filter {
        z-index: 3;
        display: grid;
        column-gap: 0.5rem;
        row-gap: 0.5rem;
        padding: 0.25rem;

        [class*=' css-'][class*='-MenuList'] {
          overflow-x: hidden;
        }

        .filter-value {
          img, svg {
            width: 32px;
            height: 32px;
          }

          .title {
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
          }

          .matches {
            font-size: 0.8rem;
            color: $text-muted;
            line-height: 1;
          }
        }

        [class*=' css-'][class*='-ValueContainer'] .filter-value {
          img, svg {
            height: 20px;
            width: 20px;
          }
        }

        [class*=' css-'][class*='-option'] .filter-value {
          display: flex;
          flex-direction: row;
          overflow: hidden;

          > * {
            align-self: center;
          }

          .title {
            margin-left: math.div($grid-gutter-width, 4);
            flex-grow: 1;
          }

          .matches {
            margin-left: math.div($grid-gutter-width, 6);
          }
        }

        .filter-pipelineStates .filter-value svg {
          width: 20px;
          height: 20px;
        }

        @media(min-width: map-get($grid-breakpoints, "md")) {
          grid-template-columns: 1fr;
        }
        @media(min-width: map-get($grid-breakpoints, "lg")) {
          grid-template-columns: 1fr 1fr 1fr 1fr;
        }
      }
    }

    .project-entries {
      display: grid;
      column-gap: 1rem;
      row-gap: 1rem;

      @media(min-width: map-get($grid-breakpoints, "xs")) {
        grid-template-columns: 1fr;
      }
      @media(min-width: map-get($grid-breakpoints, "sm")) {
        grid-template-columns: 1fr 1fr;
      }
      @media(min-width: map-get($grid-breakpoints, "md")) {
        grid-template-columns: 1fr 1fr;
      }
      @media(min-width: map-get($grid-breakpoints, "lg")) {
        grid-template-columns: 1fr 1fr 1fr;
      }
      @media(min-width: map-get($grid-breakpoints, "xl")) {
        grid-template-columns: 1fr 1fr 1fr 1fr;
      }
    }

    .project-entry {
      z-index: 1;

      .card-header {
        overflow: hidden;
        white-space: nowrap;
        display: flex;

        .project-avatar {
          height: 2rem;
          width: 2rem;
          margin-right: 0.3rem;
        }

        .project-name-wrapper {
          display: flex;
          flex-direction: column;
          overflow: hidden;
          flex-grow: 1;

          > * {
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
          }

          .project-namespace {
            font-size: 0.7rem;
            line-height: 0.8rem;
            font-style: italic;
          }

          .project-name {
            font-size: 1rem;
            line-height: 1.3rem;
          }
        }
      }

      .card-body {
      }

      .card-footer {
        padding: math.div($grid-gutter-width, 3);
        font-size: 0.8rem;

        span:first-child {
          margin-left: 0;
        }

        > span {
          margin-left: math.div($grid-gutter-width, 3);
        }
      }

      @media(min-width: map-get($grid-breakpoints, "md")) {
        &:hover {
          z-index: 2;
          border-bottom-left-radius: 0;
          border-bottom-right-radius: 0;

          .project-pipelines.sub {
            display: block;
          }
        }
        .project-pipelines.sub {
          display: none;
          position: absolute;
          background-color: $card-bg;
          margin-left: calc(-#{$card-spacer-x} + -#{$card-border-width});
          margin-right: calc(-#{$card-spacer-x} + -#{$card-border-width});
          width: calc(100% + 2 * #{$card-border-width});
          background-clip: border-box;
          border: $card-border-width solid $card-border-color;
          @include border-radius(0 0 $card-border-radius $card-border-radius);
          padding: $card-spacer-x;
        }
      }

      .project-pipelines {
        list-style: none;
        padding: 0;
        margin: 0;

        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        column-gap: 0.2rem;
        row-gap: 0.2rem;

        .project-pipeline {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;

          &[data-protected="true"] {
            a {
              font-weight: 600;
            }
          }

          .pipeline-ref {
            margin-left: 0.3rem;
          }
        }
      }
    }

  }

  .project-details-view {
    display: grid;
    grid-gap: math.div($grid-gutter-width, 2);
    grid-template: 'details' 'branches' 'pipelines' 'releases' 'readme' 'last-update';
    @media(min-width: map-get($grid-breakpoints, "sm")) {
      grid-template:
        'details branches'
        'pipelines pipelines'
        'releases releases'
        'readme readme'
        'last-update last-update';
    }
    @media(min-width: map-get($grid-breakpoints, "md")) {
      grid-template:
        'details branches'
        'pipelines pipelines'
        'releases releases'
        'readme readme'
        'last-update last-update';
    }
    @media(min-width: map-get($grid-breakpoints, "lg")) {
      grid-template:
        'details branches'
        'pipelines pipelines'
        'releases releases'
        'readme readme'
        'last-update last-update';
    }
    @media(min-width: map-get($grid-breakpoints, "xl")) {
      grid-template:
        'details details details branches branches'
        'pipelines pipelines pipelines pipelines releases'
        'readme readme readme readme readme'
        'last-update last-update last-update last-update last-update';
    }

    .last-update {
      grid-area: last-update;
    }

    .details {
      grid-area: details;

      .card-header {
        display: flex;
        align-items: center;

        .logo {
          height: 3em;
          width: 3em;
          margin-right: 1em;
        }

        .back-link {
          font-size: 1.8rem;
          line-height: 1.8rem;
        }

        h1 {
          display: flex;
          flex-direction: column;
          margin: 0;

          .namespace {
            font-size: 1rem;
            line-height: 1rem;
            font-style: italic;
          }

          .name {
            line-height: 2rem;
            font-size: 2rem;
          }
        }
      }
    }

    .branches {
      grid-area: branches;
      align-self: start;

      ul {
        list-style: none;
        margin: 0;
        padding: 0;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;

        li {
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }
      }
    }

    .pipelines {
      grid-area: pipelines;
    }

    .readme {
      grid-area: readme;

      .text {
        overflow: auto;
      }
    }

    .releases {
      grid-area: releases;

      .major-release {
        &.open h3 {
          background-color: $gray-200;
        }
      }

      h3 {
        cursor: pointer;
        padding: 0.5rem 1rem;
        font-size: $h5-font-size;
        border-bottom: 1px solid $gray-200;
        background-color: $gray-100;
        margin: 0;
      }

      button {
        margin-left: 0;
      }
    }

    .project-readme {
      display: flex;
      flex-direction: row;

      .toc {
        position: relative;
        padding-right: math.div($grid-gutter-width, 2);

        > ul {
          top: math.div($grid-gutter-width, 2);
          position: sticky;
          padding-left: 0;
        }

        ul {
          padding-left: 1rem;
        }
      }
    }
  }

  .pipeline-status {
    .pipeline-icon {
      cursor: pointer;
    }
  }

  .project-pipeline-popover {
    ul {
      list-style: none;
      display: flex;
      padding: 0;
      margin: 0;

      li {
        white-space: nowrap;
        border: 1px solid $tooltip-color;
        border-radius: 0.25rem;
        position: relative;
        margin-right: calc(1rem - 2px);
        padding: 3px;

        a {
          padding-left: 3px;
        }

        &:after {
          position: absolute;
          content: "";
          right: -1rem;
          width: 1rem;
          height: 1px;
          background-color: $tooltip-color;
          transform: translateY(-1px);
          top: 50%;
        }

        &:last-child {
          &:after {
            display: none;
          }
        }
      }
    }
  }
}