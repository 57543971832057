@use "sass:math";

.route-users {
  .users-view {
    header {
      margin-top: math.div($grid-gutter-width, 2);
      display: flex;

      h1 {
        flex-grow: 1;
      }

      [role] {
        align-self: center;
      }
    }

    .listing {
      display: grid;
      grid-template-columns: 1fr;
      @media(min-width: map-get($grid-breakpoints, "lg")) {
        grid-template-columns: 1fr 1fr;
      }
      grid-column-gap: 1em;
      grid-row-gap: 1em;
      margin-top: math.div($grid-gutter-width, 2);

      .card-header {
        display: flex;

        h2 {
          display: inline-block;
          margin: 0;
          vertical-align: middle;
          flex-grow: 1;
          padding: math.div($grid-gutter-width, 2);
        }

        .avatar {
          align-self: center;
        }

        [role='status'], [role='remove'], [role='edit'] {
          align-self: center;
        }
      }
    }
  }
}

.user-modal {
  label {
    font-weight: bold;
  }

  .tab-pane {
    padding-top: math.div($grid-gutter-width, 2);
  }

  .permissions {
    list-style: none;
    display: grid;
    padding-left: 0;
    grid-template-columns: 1fr 1fr 1fr;
    margin: 0;

    [class*=' css-'][class*='-MenuList'] {
      overflow-x: hidden;
    }

    li {
      padding: math.div($grid-gutter-width, 3);
    }

    input {
      margin-left: math.div($grid-gutter-width, 6);
      margin-top: 0;
      position: relative;
    }

    label {
      margin-bottom: 0;
    }
  }

  .avatar {
    width: 250px;
    height: 250px;
  }

  .avatar-editor {
    position: relative;
    width: 250px;

    > .btn {
      position: absolute;
      bottom: math.div($grid-gutter-width, 2);
      right: math.div($grid-gutter-width, 2);
    }
  }
}

.route-profile {
  .avatar {
    width: 250px;
    height: 250px;
  }

  .avatar-editor {
    position: relative;
    width: 250px;

    > .btn {
      position: absolute;
      bottom: math.div($grid-gutter-width, 2);
      right: math.div($grid-gutter-width, 2);
    }
  }
}